<template>
  <div class="securityOperationAlls">
    <div class="securityOperationFirst">
      <div class="securityOperationFirst1">
        <h1>天地人机一体化</h1>
        <p class="securityOperationFirst1-1">
          以用户业务最终安全为目标，通过统筹运用多种技术和管理手段，将安全能力持续输出传递给用户的过程，其核心价值在于发现问题、验证问题、分析问题、响应处置和解决问题，不断迭代优化安全问题的处置能力，持续将安全风险降低至用户可接受的范围。
        </p>
      </div>
    </div>
    <div class="securityOperationSecond">
      <div class="securityOperationSecondLeft">
        <h1>安全能力持续有效， 迭代提升的运营服务</h1>
        <p class="securityOperationSecondLeft1">
          御之安科技安全运营体系是以用户业务最终安全为目标，通过统筹运用多种技术和管理手段，将安全能力持续输出传递给用户的过程，其核心价值在于发现问题、验证问题、分析问题、响应处置和解决问题，不断迭代优化安全问题的处置能力，持续将安全风险降低至用户可接受的范围。
        </p>
      </div>
      <div class="securityOperationSecondRight">
        <img
          src="https://www.dbappsecurity.com.cn/images/machine_01.jpg"
          alt=""
          style="width:591px"
        />
      </div>
    </div>
    <div class="securityOperationThird">
      <div class="securityOperationThird1">
        <h1>安全运营服务包分为三类：</h1>
        <p class="securityOperationThird1-1">
          轻量级的远程安全托管运营服务（MSS服务）、增强型的基于产品的可管理威胁检测与响应服务（MDR服务）和综合型安全运营中心建设与运营（Cyber SOC）。其中MSS服务侧重于基于远程方式强化安全边界防御能力；MDR服务侧重于基于产品安全的安全威胁的深度检测与响应处置；安全运营中心建设侧重于体系化、常态化的综合安全保障体系规划、建设和运行。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.securityOperationAlls {
  width: 100%;
  .securityOperationFirst {
    background-image: url(https://www.dbappsecurity.com.cn/images/machine_banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    padding: 150px;
    .securityOperationFirst1 {
      max-width: 73%;
      margin: auto;
      color: #fff;
      h1 {
        font-size: 48px;
      }
      .securityOperationFirst1-1 {
        margin-bottom: 5%;
        width: 36%;
      }
    }
  }
  .securityOperationSecond {
    background: #ededed;
    margin:20px auto;
    display: flex;
    justify-content: center;
    padding: 20px;
    .securityOperationSecondLeft {
      width: 35.6%;
      padding: 100px 80px;
      padding-right: 60px;
      h1 {
        margin-bottom: 5%;
        line-height: 1.3em;
      }
      .securityOperationSecondLeft1 {
        line-height: 30px;
        overflow: hidden;
        font-size: 16px;
      }
    }
  }
  .securityOperationThird {
    background-image: url(https://www.dbappsecurity.com.cn/images/machine_04.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    padding: 150px;
    .securityOperationThird1 {
      max-width: 72%;
      margin: auto;
      color: #fff;
      h1 {
        font-size: 48px;
      }
      .securityOperationThird1-1 {
        margin-bottom: 5%;
        width: 80%;
      }
    }
  }
}
</style>